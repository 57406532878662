<template>
  <v-chip
    v-bind="ent_chips"
    :color="setcolor_Chip"
    :style="setcss_Chip"
    @click="click_chip()"
  >
    <!-- Botones genéricos -->
    <template v-if="children > 0">
      <span
        :title="
          chip_values[0]
            ? ent_iconschipSel[0].tooltip
            : ent_iconschipUnsel[0].tooltip
        "
        :style="setcss_Icons(chip_values[0])"
        @click="click_basicBtns(0)"
      >
        <v-icon v-bind="ent_iconsChip">{{
          chip_values[0] ? ent_iconschipSel[0].icon : ent_iconschipUnsel[0].icon
        }}</v-icon>
      </span>
    </template>

    <template v-else>
      <span
        v-for="(item, index) in chip_values"
        :key="index"
        :title="
          item
            ? ent_iconschipSel[index].tooltip
            : ent_iconschipUnsel[index].tooltip
        "
        :style="setcss_Icons(item)"
        @click="click_basicBtns(index)"
      >
        <v-icon v-bind="ent_iconsChip">{{
          item ? ent_iconschipSel[index].icon : ent_iconschipUnsel[index].icon
        }}</v-icon>
      </span>
    </template>
  </v-chip>
</template>

<script>
export default {
  props: ["cfg", "chip_values", "clonando", "permisos_customBtns", "children"],
  data() {
    return {
      // variables de configuración
      ent_chips: this.cfg,
      ent_iconsChip: this.cfg.iconos,
      ent_iconschipSel: this.cfg.iconos.iconsSel,
      ent_iconschipUnsel: this.cfg.iconos.iconsUnsel,
      ent_iconcustomBtns: this.cfg.iconos.icon_customBtns,
      seleccionado: false,
      seleccionado_clonacion: false
    };
  },

  computed: {
    setcolor_Chip() {
      // devuelvo el color de fondo del chip
      if (this.seleccionado_clonacion)
        return this.ent_chips.colorselectClonacion;
      //if (this.seleccionado) return this.ent_chips.colorselectChip;
      return this.chip_values[0]
        ? this.ent_chips.colorActive || "green"
        : this.ent_chips.colorInactive || "red";
    },

    setcss_Chip() {
      if (this.seleccionado) return "box-shadow:inset 0 0 0 1.5px #888";
      return "";
    }
  },

  methods: {
    setcss_Icons(permiso) {
      return (
        "color:" +
        (permiso
          ? this.ent_chips.coloriconActive
          : this.ent_chips.coloriconInactive)
      );
    },

    click_chip() {
      if (this.clonando) return;
      this.$emit("change_customBtns", this.permisos_customBtns);
      this.$emit("update_chipSeleccionado", this);
    },

    click_basicBtns(index) {
      if ((index !== 0 && this.chip_values[0] === false) || this.clonando)
        return;
      this.chip_values.splice(index, 1, !this.chip_values[index]);
    }
  }
};
</script>
